import { withDependencies, named } from '@wix/thunderbolt-ioc'
import { PageFeatureConfigSymbol, IPropsStore, IStructureAPI, Props, StructureAPI } from '@wix/thunderbolt-symbols'
import { name, IMAGE_ZOOM_QUERY, IMAGE_ZOOM_ID } from './symbols'
import { ImageZoomPageConfig, ImageZoomAPI, ImageZoomGalleryProps } from './types'
import { ISiteScrollBlocker, SiteScrollBlockerSymbol } from 'feature-site-scroll-blocker'
import { IUrlHistoryManager, UrlHistoryManagerSymbol } from 'feature-router'

export const ImageZoomAPIImpl = withDependencies(
	[named(PageFeatureConfigSymbol, name), Props, StructureAPI, SiteScrollBlockerSymbol, UrlHistoryManagerSymbol],
	(
		{ isPopupPage, imageZoomCompType, tpaGalleriesComps }: ImageZoomPageConfig,
		propsStore: IPropsStore,
		structureAPI: IStructureAPI,
		siteScrollBlocker: ISiteScrollBlocker,
		urlHistoryManager: IUrlHistoryManager
	): ImageZoomAPI => {
		const setZoomDataToUrl = (dataItemId: string | null) => {
			const url = urlHistoryManager.getParsedUrl()
			if (dataItemId) {
				url.searchParams.set(IMAGE_ZOOM_QUERY, dataItemId)
			} else {
				url.searchParams.delete(IMAGE_ZOOM_QUERY)
			}
			urlHistoryManager.pushUrlState(url)
		}

		const addComponent = async (imageZoomProps: ImageZoomGalleryProps) => {
			propsStore.update({ [IMAGE_ZOOM_ID]: imageZoomProps })
			await structureAPI.addComponentToDynamicStructure(IMAGE_ZOOM_ID, {
				componentType: imageZoomCompType,
				components: [],
			})
			siteScrollBlocker.setSiteScrollingBlocked(true, IMAGE_ZOOM_ID)
		}
		const removeComponent = () => {
			if (!structureAPI.isComponentInDynamicStructure(IMAGE_ZOOM_ID)) {
				return
			}
			structureAPI.removeComponentFromDynamicStructure(IMAGE_ZOOM_ID)
			siteScrollBlocker.setSiteScrollingBlocked(false, IMAGE_ZOOM_ID)
		}

		const closeImageZoom = () => {
			removeComponent()

			setZoomDataToUrl(null)
		}
		const onImageChange = (dataItemId: string) => {
			setZoomDataToUrl(dataItemId)
		}

		const convertTpaGalleryImagesToImageZoomFormat = (images: any, compId: string) => {
			return images.map((image: any) => {
				const { id, uri, alt, width, height, href, link, title, type, description } = image
				const linkProp = href ? { link: { ...link, href } } : {}
				return {
					id,
					containerId: compId,
					uri,
					alt,
					name: image.name,
					width,
					height,
					title,
					type,
					description,
					...linkProp,
				}
			})
		}

		const openImageZoom = async (compId: string, dataItemId: string) => {
			const [templateId] = compId.split('__')
			const compProps = { ...propsStore.get(templateId), ...propsStore.get(compId), containerId: compId } as any
			if (structureAPI.isComponentInDynamicStructure(IMAGE_ZOOM_ID)) {
				return
			}

			// compId is either of a gallery or a wPhoto
			const isGallery = tpaGalleriesComps.hasOwnProperty(compId)
			const getGalleryCompImageProps = () => {
				const tpaCompProps = compProps
				return convertTpaGalleryImagesToImageZoomFormat(tpaCompProps.images, compId)
			}

			const images = isGallery ? getGalleryCompImageProps() : [compProps]

			const imageZoomProps: ImageZoomGalleryProps = {
				images,
				onClose: closeImageZoom,
				onImageChange,
				dataItemId,
				compId,
			}
			await addComponent(imageZoomProps)

			if (!isPopupPage) {
				setZoomDataToUrl(dataItemId)
			}
		}

		return {
			openImageZoom,
			closeImageZoom,
		}
	}
)
